import {
    SET_IS_FETCHING,
    GET_ORDER_DETAILS,
    CLEAR_ORDER_DETAILS,
} from '../constants/action-types';

const initialState = {
    orderDetailsById: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_FETCHING: {
            const { orderId } = action;
            state = {
                ...state,
                orderDetailsById: {
                    ...state.orderDetailsById,
                    [orderId]: {
                        isFetching: true,
                        data: null,
                    },
                },
            };
            break;
        }
        case GET_ORDER_DETAILS: {
            const { orderId, payload } = action;
            state = {
                ...state,
                orderDetailsById: {
                    ...state.orderDetailsById,
                    [orderId]: {
                        isFetching: false,
                        data: payload ? payload : null,
                    },
                },
            };
            break;
        }
        case CLEAR_ORDER_DETAILS: {
            state = {
                ...state,
                orderDetailsById: initialState.orderDetailsById,
            };
            break;
        }
        default:
            break;
    }
    return state;
};
