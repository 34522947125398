//@flow

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Divider, LinkText, TitleContainer } from '../utils';
import ClientContext from '../../ClientContext';

const ServiceTemporaryUnavailable = () => {
    const { clientTheme } = React.useContext(ClientContext);
    const { t } = useTranslation();

    return (
        <Box pt="24px" pb="24px">
            <TitleContainer
                title={t('serviceUnavailable')}
                dividerProps={{ mt: '16px' }}
            />
            <Divider />
            <Typography variant="body">
                <Box pt="24px">{t('apologiesError')}</Box>
                <Box pt="8px">{t('goBackPreviousPage')}</Box>
                <Box pt="8px">{t('goToContactUsPage')}</Box>
            </Typography>
            <Box pt="24px">
                <Typography variant="caption">
                    <LinkText
                        hRefLink={clientTheme.brand['frUniqlowebsiteUrl']}
                    >{`${t('uniqlo')} ${t('homePage')}`}</LinkText>
                </Typography>
            </Box>
            <Box pt="16px">
                <Typography variant="caption">
                    <LinkText
                        hRefLink={clientTheme.brand['frGuwebsiteUrl']}
                    >{`${t('gu')} ${t('homePage')}`}</LinkText>
                </Typography>
            </Box>
            <Box pt="16px">
                <Typography variant="captionContact">
                    {clientTheme.brand['contactNo']}
                </Typography>
            </Box>
        </Box>
    );
};

export default ServiceTemporaryUnavailable;
