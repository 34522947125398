//@flow

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderSummary from './OrderSummary/OrderSummary';
import RoutesEnum from '../../constants/RoutesEnum';
import ReDelivery from './ReDelivey/ReDelivery';
import ReDeliveryConfirm from './ReDelivey/ReDeliveryConfirm';
import {
    PageNotFound,
    ServiceTemporaryUnavailable,
    ExcuseUs,
} from './MessagePage';

const AppRoutes = (): React.Node => (
    <Switch>
        <Route exact path={RoutesEnum.LandingPage} component={LandingPage} />
        <Route exact path={RoutesEnum.Details} component={OrderDetails} />
        <Route exact path={RoutesEnum.Summary} component={OrderSummary} />
        <Route
            exact
            path={RoutesEnum.ReDelivery}
            render={(props) => (
                <ReDelivery {...props} isUpdateRequest={false} />
            )}
        />
        <Route
            exact
            path={RoutesEnum.UpdateDelivery}
            render={(props) => <ReDelivery {...props} isUpdateRequest={true} />}
        />
        <Route
            exact
            path={RoutesEnum.ConfirmReDelivery}
            render={(props) => (
                <ReDeliveryConfirm {...props} isUpdateRequest={false} />
            )}
        />
        <Route
            exact
            path={RoutesEnum.ConfirmUpdateDelivery}
            render={(props) => (
                <ReDeliveryConfirm {...props} isUpdateRequest={true} />
            )}
        />
        <Route
            exact
            path={'/service-down'}
            component={ServiceTemporaryUnavailable}
        />
        <Route exact path={'/excuse-us'} component={ExcuseUs} />
        <Route path={'/'} component={PageNotFound} />
    </Switch>
);

export default AppRoutes;
