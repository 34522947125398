// @flow
import './polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createBrowserHistory } from 'history';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import Loader from './jsx/components/Common/Loader';

import Root from './jsx/routes/Root';
import { QueryProvider } from 'query';
import theme from './theme';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './app.css';
import store from './jsx/store/index';
import config from 'config';

export const history = createBrowserHistory();
Bugsnag.start({
    apiKey: 'e760a1ee6efda221fbce076deed73fd4',
    plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
moment.tz.setDefault(config.timezone);

export const AppContext = React.createContext();

const App = () => {
    const [showBanner, setShowBannerMsg] = React.useState(false);

    const value = {
        showBanner,
        setShowBannerMsg: (data) => setShowBannerMsg(data),
    };

    return (
        <Provider store={store}>
            <ErrorBoundary>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <QueryProvider>
                        <AppContext.Provider value={value}>
                            <React.Suspense fallback={<Loader />}>
                                <I18nextProvider i18n={i18n}>
                                    <Root history={history} />
                                </I18nextProvider>
                            </React.Suspense>
                        </AppContext.Provider>
                    </QueryProvider>
                </ThemeProvider>
            </ErrorBoundary>
        </Provider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
