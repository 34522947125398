//@flow

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../index';
import AppBreadcrumbs from '../../Common/AppBreadcrumbs';
import OrderSummaryItem from './OrderSummaryItem';
import ClientContext from '../../ClientContext';
import { CustomButtonOutline, TitleContainer, Inquiries } from '../utils';
import RoutesEnum from '../../../constants/RoutesEnum';
import Loader from '../../Common/Loader';
import {
    clearOrderDetails,
    getOrderDetails,
} from '../../../../jsx/actions/trackTask';
import { ServiceTemporaryUnavailable, ExcuseUs } from '../MessagePage';
import { useStyles } from './OrderSummaryStyles';

const OrderSummary = () => {
    const { clientId } = React.useContext(ClientContext);
    const [errorComponent, setErrorComponent] = React.useState(null);

    const dispatch = useDispatch();
    const orderDetailsById = useSelector(
        (state) => state.trackTask.orderDetailsById,
    );
    const { t } = useTranslation();
    const location = useLocation();
    const classes = useStyles();
    const { trackingId } = queryString.parse(location.search);
    const ordersIds = trackingId ? trackingId.split(',') : [];

    React.useEffect(() => {
        const cb = (isSuccess, message) => {
            if (isSuccess) return;
            if (message === 'Network Error') setErrorComponent('excuseUs');
            else setErrorComponent('serviceUnAvailable');
        };
        ordersIds.forEach((orderId) =>
            dispatch(getOrderDetails(clientId, orderId, cb)),
        );

        return () => dispatch(clearOrderDetails());
    }, []);

    const isFetching = React.useMemo(
        () =>
            Object.keys(orderDetailsById).some(
                (orderId) => orderDetailsById[orderId].isFetching,
            ),
        [orderDetailsById],
    );

    if (errorComponent) {
        if (errorComponent === 'excuseUs') return <ExcuseUs />;
        else if (errorComponent === 'serviceUnAvailable')
            return <ServiceTemporaryUnavailable />;
    }
    if (isFetching) return <Loader />;

    return (
        <Box>
            <AppBreadcrumbs />
            <TitleContainer
                title={t('deliveryStatusList')}
                dividerProps={{
                    mt: `${
                        Object.keys(orderDetailsById).length > 0
                            ? '24px'
                            : '16px'
                    }`,
                }}
            />
            {Object.keys(orderDetailsById).length <= 0 && (
                <Box className={classes.errorText} pt="24px">
                    <Typography variant="body">
                        {ordersIds.length <= 0
                            ? t('pleaseCheckDeliverySlipNo')
                            : t('noRecordForSlipNumber')}
                    </Typography>
                </Box>
            )}
            {ordersIds.map((orderId) => (
                <OrderSummaryItem
                    key={orderId}
                    orderId={orderId}
                    detail={orderDetailsById?.[orderId]?.data}
                />
            ))}
            <Box pt="32px" width="100%" className={classes.buttonContainer}>
                <CustomButtonOutline
                    variant="outlined"
                    width="100%"
                    onClick={() =>
                        history.push({
                            pathname: RoutesEnum.LandingPage,
                            search: queryString.stringify({
                                ...queryString.parse(history.location.search),
                                trackingId: trackingId,
                            }),
                        })
                    }
                >
                    {t('trackYourSlipNumber')}
                </CustomButtonOutline>
            </Box>
            <Inquiries />
        </Box>
    );
};

export default OrderSummary;
