//@flow

import * as React from 'react';
import ClientSettings from './ClientSettings';
import config from 'config';

let clientId = null;

if (config.manuallyEnterClientId) clientId = window.prompt('Enter Client Id');
else if (config.clientId) clientId = config.clientId;
else clientId = window.location.href.split('.')[0]?.split('https://')[1];

export default React.createContext({
    clientId,
    clientTheme:
        clientId && ClientSettings[clientId]
            ? ClientSettings[clientId]
            : ClientSettings['defaultSetting'],
});
