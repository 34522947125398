//@flow

import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { history } from '../../../../index';
import {
    CustomButtonContain,
    CustomButtonOutline,
    Inquiries,
    TitleContainer,
} from '../utils';
import RoutesEnum from '../../../constants/RoutesEnum';
import AppBreadcrumbs from '../../Common/AppBreadcrumbs';
import ClientContext from '../../ClientContext';
import Loader from '../../Common/Loader';
import {
    getOrderDetails,
    clearOrderDetails,
} from '../../../../jsx/actions/trackTask';
import { ServiceTemporaryUnavailable, ExcuseUs } from '../MessagePage';
import OrderDetailsStep from './OrderDetailsStep';
import {
    REDELIVERY_TOP_BUTTON_STATUS_CODE,
    RESCHEDULE_BOTTOM_BUTTON_STATUS_CODE,
    IGNORED_CURRENT_STATUS_CODE_BUTTON_RULE,
    IGNORED_REPEATED_STATUS_CODE_LIST_DISPLAY,
    IGNORED_CURRENT_STATUS_CODE_BOTTOM_BUTTON_MESSAGE_UI,
    USE_UNATTENDED_DELIVERY_METHOD,
    ANY_TIME_SLOT_ID,
} from '../../../constants';
import jaLanguage from '../../../../locales/ja.json';

const DELIVERY_LOCATION = jaLanguage.deliveryMethod;
const ORDER_STATUS = jaLanguage.orderStatus;

export const getCustomizedOrderDetails = (orderDetail, t) => {
    const date = moment(orderDetail?.slot?.start).format('YYYY/MM/DD');
    const timeId = `${moment(orderDetail?.slot?.start).format('HH')}${moment(
        orderDetail?.slot?.end,
    ).format('HH')}`;
    const startTime = moment(orderDetail?.slot?.start).format('HH:mm');
    const endTime = moment(orderDetail?.slot?.end).format('HH:mm');
    const sortedList =
        orderDetail && Array.isArray(orderDetail.statusHistory)
            ? orderDetail.statusHistory?.sort(
                  (a, b) =>
                      new Date(b.updatedDate).valueOf() -
                      new Date(a.updatedDate).valueOf(),
              )
            : [];
    const filteredList = sortedList.reduce((obj, item, index) => {
        const prevElement = obj[obj.length - 1];
        const nextElement = sortedList[index + 1];
        const prevStatusCode = prevElement?.trackingPageStatusCode;
        const currentStatusCode = item?.trackingPageStatusCode;

        // Ignore the status from locus list.
        if (!currentStatusCode) return obj;

        // Ignore the status if the current & previous status are same.
        const prevStatus = t(`orderStatus.${prevStatusCode}`);
        const currStatus = t(`orderStatus.${currentStatusCode}`);
        if (prevStatus === currStatus) return obj;

        // Ignore the status due to client requirement only when next status is available.
        const haveToIgnoreStatus =
            IGNORED_REPEATED_STATUS_CODE_LIST_DISPLAY.includes(
                currentStatusCode,
            ) && nextElement;
        if (haveToIgnoreStatus) return obj;

        return [...obj, item];
    }, []);

    return { date, timeId, startTime, endTime, sortedList, filteredList };
};

export const getButtonStatus = (list) => {
    for (const item of list) {
        const statusCode = item?.trackingPageStatusCode;
        if (!statusCode) continue;
        if (IGNORED_CURRENT_STATUS_CODE_BUTTON_RULE.includes(statusCode))
            continue;
        else if (REDELIVERY_TOP_BUTTON_STATUS_CODE.includes(statusCode))
            return { showTopButton: true, showBottomButton: false };
        else if (RESCHEDULE_BOTTOM_BUTTON_STATUS_CODE.includes(statusCode))
            return { showTopButton: false, showBottomButton: true };
        else return { showTopButton: false, showBottomButton: false };
    }

    return { showTopButton: false, showBottomButton: false };
};

const useStyles = () => {
    const style = makeStyles((theme) => {
        return {
            errorText: {
                color: '#EE0000',
            },
            [theme.breakpoints.down('md')]: {
                btnGroupContainer: {
                    paddingTop: '8px',
                },
                buttonContainer: {
                    paddingTop: '24px',
                },
            },
            [theme.breakpoints.up('md')]: {
                btnGroupContainer: {
                    display: 'flex',
                    paddingTop: '32px',
                },
                buttonContainer: {
                    maxWidth: '348px',
                    marginRight: '24px',
                    display: 'flex',
                },
            },
            [theme.breakpoints.up('lg')]: {
                btnGroupContainer: {
                    display: 'flex',
                    paddingTop: '32px',
                },
                buttonContainer: {
                    maxWidth: '364px',
                    marginRight: '24px',
                    display: 'flex',
                },
            },
        };
    });
    return style();
};

const OrderDetails = () => {
    const { clientId } = React.useContext(ClientContext);
    const dispatch = useDispatch();
    const orderDetailsById = useSelector(
        (state) => state.trackTask.orderDetailsById,
    );
    const { t } = useTranslation();
    const location = useLocation();
    const classes = useStyles();

    const [errorComponent, setErrorComponent] = React.useState(null);
    const { trackingId } = queryString.parse(location.search);
    const ordersIds = trackingId ? trackingId.split(',') : [];
    const orderId = ordersIds.length > 0 ? ordersIds[0] : null;

    React.useEffect(() => {
        const cb = (isSuccess, message) => {
            if (isSuccess) return;
            if (message === 'Network Error') setErrorComponent('excuseUs');
            else setErrorComponent('serviceUnAvailable');
        };
        if (orderId) dispatch(getOrderDetails(clientId, orderId, cb));

        return () => dispatch(clearOrderDetails());
    }, []);

    const detail = React.useMemo(() => orderDetailsById?.[orderId]?.data, [
        orderDetailsById,
    ]);

    const {
        date,
        timeId,
        startTime,
        endTime,
        sortedList,
        filteredList,
    } = React.useMemo(() => getCustomizedOrderDetails(detail, t), [detail]);

    const latestStatus = React.useMemo(
        () => (filteredList ? filteredList[0] : {}),
        [filteredList],
    );

    const { showTopButton, showBottomButton } = React.useMemo(
        () => getButtonStatus(sortedList),
        [sortedList],
    );

    const handleReDeliveryRequest = () => {
        const deliveryLocation = detail?.deliveryLocation
            ? detail?.deliveryLocation
            : detail?.deliveryLocationOriginal;

        history.push({
            pathname: RoutesEnum.ReDelivery,
            search: queryString.stringify({
                ...queryString.parse(history.location.search),
                trackingId: orderId,
            }),
            state: {
                deliveryMethod: deliveryLocation,
                brand: detail?.brand,
            },
        });
    };

    if (errorComponent) {
        if (errorComponent === 'excuseUs') return <ExcuseUs />;
        else if (errorComponent === 'serviceUnAvailable')
            return <ServiceTemporaryUnavailable />;
    }

    if (orderDetailsById?.[orderId]?.isFetching) return <Loader />;

    return (
        <Box>
            <AppBreadcrumbs />
            <TitleContainer
                title={`${t('deliverySlipNo')}: ${orderId ?? ''}`}
                dividerProps={{ mt: '16px' }}
            />
            {!detail && (
                <Box className={classes.errorText} pt="24px">
                    <Typography variant="body">
                        {!orderId
                            ? t('pleaseCheckDeliverySlipNo')
                            : t('noRecordForSlipNumber')}
                    </Typography>
                </Box>
            )}
            {detail && showTopButton && (
                <Box pt="24px" maxWidth="752px">
                    <Typography variant="body">
                        {t('deliveryNotDoneRequestReDelivery')}
                    </Typography>
                </Box>
            )}
            {detail &&
                showBottomButton &&
                !IGNORED_CURRENT_STATUS_CODE_BOTTOM_BUTTON_MESSAGE_UI.includes(
                    latestStatus?.trackingPageStatusCode,
                ) && (
                    <Box pt="24px" pb="8px" maxWidth="752px">
                        <Typography variant="body">
                            {t('cantCompleteDelivery')}
                        </Typography>
                    </Box>
                )}
            <Box>
                {detail && (
                    <Box>
                        {showTopButton && (
                            <Box className={classes.btnGroupContainer}>
                                <Box
                                    width="100%"
                                    className={classes.buttonContainer}
                                >
                                    <CustomButtonContain
                                        variant="contained"
                                        width="100%"
                                        onClick={handleReDeliveryRequest}
                                    >
                                        {t('requestReDelivery')}
                                    </CustomButtonContain>
                                </Box>
                            </Box>
                        )}
                        <Box pt="24px">
                            <Typography variant="h3">
                                {`${
                                    showBottomButton
                                        ? t('estimatedeliveryDateC')
                                        : t('deliveryDateC')
                                }: ${date} ${
                                    timeId === ANY_TIME_SLOT_ID
                                        ? t('anyTime')
                                        : `${startTime} 〜 ${endTime}`
                                }`}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body">
                                <Box pt="16px">
                                    {`${t('deliveryStatus')}: ${
                                        ORDER_STATUS[
                                            latestStatus?.trackingPageStatusCode
                                        ]
                                            ? t(
                                                  `orderStatus.${latestStatus?.trackingPageStatusCode}`,
                                              )
                                            : ''
                                    }`}
                                </Box>
                                {detail?.brand && (
                                    <Box pt="8px">{`${t('brand')}: ${
                                        detail.brand
                                    } ${t('onlineStore')}`}</Box>
                                )}
                                {DELIVERY_LOCATION[detail?.deliveryLocation] &&
                                    USE_UNATTENDED_DELIVERY_METHOD.includes(
                                        detail?.deliveryLocation,
                                    ) && (
                                        <Box>
                                            <Box pt="8px">
                                                {t('useUnattendedDelivery')}
                                            </Box>
                                            <Box pt="8px">{`${t(
                                                'deliveryLocationC',
                                            )}: ${t(
                                                `deliveryMethod.${detail?.deliveryLocation}`,
                                            )}`}</Box>
                                        </Box>
                                    )}
                            </Typography>
                        </Box>
                        <Box pt="24px">
                            {filteredList?.map((status, i) => {
                                return (
                                    <OrderDetailsStep
                                        key={i}
                                        statusDetails={status}
                                        isLatest={i === 0}
                                        isLast={i === filteredList.length - 1}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                )}
                <Box className={classes.btnGroupContainer}>
                    {detail && showBottomButton && (
                        <Box width="100%" className={classes.buttonContainer}>
                            <CustomButtonOutline
                                variant="outline"
                                width="100%"
                                onClick={() =>
                                    history.push({
                                        pathname: RoutesEnum.UpdateDelivery,
                                        state: {
                                            slot: detail?.slot,
                                            deliveryMethod:
                                                detail?.deliveryLocation,
                                            brand: detail?.brand,
                                        },
                                        search: queryString.stringify({
                                            ...queryString.parse(
                                                history.location.search,
                                            ),
                                            trackingId: orderId,
                                        }),
                                    })
                                }
                            >
                                {t('updateDateTimeLocationRequest')}
                            </CustomButtonOutline>
                        </Box>
                    )}
                    <Box width="100%" className={classes.buttonContainer}>
                        <CustomButtonOutline
                            variant="outline"
                            width="100%"
                            onClick={() =>
                                history.push({
                                    pathname: RoutesEnum.Summary,
                                    search: queryString.stringify({
                                        ...queryString.parse(
                                            history.location.search,
                                        ),
                                        trackingId: orderId,
                                    }),
                                })
                            }
                        >
                            {t('returnToPreviousPage')}
                        </CustomButtonOutline>
                    </Box>
                </Box>
            </Box>
            <Inquiries />
        </Box>
    );
};

export default OrderDetails;
