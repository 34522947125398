// @flow
import { useQuery, type QueryResult } from 'react-query';
import { instance } from 'middleware/axios';

type GetDeliverySlot = { clientId: string, orderId: string };

export function useGetDeliverySlot({
    clientId,
    orderId,
}: GetDeliverySlot): QueryResult<*> {
    return useQuery({
        queryFn: () => {
            return orderId?.length === 0
                ? null
                : instance
                      .get(
                          `locus/clientId/${clientId}/order/slip-no/${orderId}/slot?sendDetailSlotInfo=true`,
                      )
                      .then((response) => response?.data);
        },
        refetchOnWindowFocus: false,
    });
}
