//@flow

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { history } from '../../../../index';
import {
    CustomButtonContain,
    CustomButtonOutline,
    Divider,
    Inquiries,
    TitleContainer,
} from '../utils';
import AppBreadcrumbs from '../../Common/AppBreadcrumbs';
import RoutesEnum from '../../../constants/RoutesEnum';
import { ServiceTemporaryUnavailable, ExcuseUs } from '../MessagePage';
import ClientContext from '../../ClientContext';
import { updateOrderDetails } from '../../../actions/trackTask';
import Loader from '../../Common/Loader';
import deliveryLocation from '../../../../locales/ja.json';
import { USE_UNATTENDED_DELIVERY_METHOD } from '../../../constants';
import { useStyles } from './ReDeliveryStyles';

const DELIVERY_LOCATION: any = deliveryLocation.deliveryMethod;

const ReDeliveryConfirm = (props) => {
    const {
        isUpdateRequest,
        location: { state },
    } = props;

    const { clientId } = React.useContext(ClientContext);
    const titleRef = React.useRef();
    const [isApiSuccess, setIsApiSuccess] = React.useState(true);
    const [isFetching, setIsFetching] = React.useState(false);
    const [errorComponent, setErrorComponent] = React.useState(null);

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { trackingId } = queryString.parse(location.search);

    const {
        deliveryDateCd,
        deliveryTimeCdDetails,
        deliveryMethod,
        contactNumber,
        brand,
    } = state || {};

    React.useEffect(() => {
        if (!state)
            history.push({
                pathname: RoutesEnum.Details,
                search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    trackingId: trackingId,
                }),
            });

        titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const handleSubmit = () => {
        const cb = (isSuccess, res) => {
            setIsFetching(false);
            if (isSuccess) {
                if (res && !res.errorInfoList) {
                    history.push({
                        pathname: RoutesEnum.Details,
                        search: queryString.stringify({
                            ...queryString.parse(history.location.search),
                            trackingId: trackingId,
                        }),
                    });
                } else setIsApiSuccess(false);
            } else {
                if (res === 'Network Error') setErrorComponent('excuseUs');
                else setErrorComponent('serviceUnAvailable');
            }
        };

        const data = {
            deliveryDateCd,
            deliveryTimeCd: deliveryTimeCdDetails?.id,
            alternateContactNumber: contactNumber,
            deliveryMethod,
        };
        setIsFetching(true);
        dispatch(updateOrderDetails(clientId, trackingId, data, cb));
    };

    if (errorComponent) {
        if (errorComponent === 'excuseUs') return <ExcuseUs />;
        if (errorComponent === 'serviceUnAvailable')
            return <ServiceTemporaryUnavailable />;
    }

    if (isFetching) return <Loader />;

    return (
        <Box ref={titleRef}>
            <AppBreadcrumbs />
            <TitleContainer
                title={`${
                    isUpdateRequest
                        ? t('updateReschedule')
                        : t('reDeliveryRequest')
                }`}
                dividerProps={{ mt: '16px' }}
            />
            <Divider />
            {!isApiSuccess && (
                <Box pt="24px" className={classes.errorText} maxWidth="752px">
                    <Typography variant="body">
                        {isUpdateRequest
                            ? t('returnToUpdateRequestErrorMsg')
                            : t('returnToReRequestErrorMsg')}
                    </Typography>
                </Box>
            )}
            <Box pt="24px">
                <Typography variant="h2">
                    {t('estimatedeliveryDateC')}
                </Typography>
            </Box>
            <Box pt="24px" pb="32px">
                <Typography variant="body">
                    {`${t('deliveryDate')}: ${moment(
                        deliveryDateCd,
                        'YYYYMMDD',
                    ).format('YYYY/MM/DD')} ${
                        deliveryTimeCdDetails?.id === '0000'
                            ? t('anyTime')
                            : `${deliveryTimeCdDetails?.start}:00 ~ ${deliveryTimeCdDetails?.end}:00`
                    }`}
                </Typography>
            </Box>
            <Divider dividerWidth="752px" />
            <Box pt="32px">
                <Typography variant="h2">{t('deliveryLocationC')}</Typography>
            </Box>
            <Typography variant="body">
                {deliveryMethod && (
                    <Box pt="24px">
                        {USE_UNATTENDED_DELIVERY_METHOD.includes(deliveryMethod)
                            ? t('useUnattendedDelivery')
                            : t('doNotUseUnattendedDelivery')}
                    </Box>
                )}
                {USE_UNATTENDED_DELIVERY_METHOD.includes(deliveryMethod) && (
                    <Box pt="8px">{`${t('deliveryLocation')}: ${
                        DELIVERY_LOCATION[deliveryMethod]
                            ? t(`deliveryMethod.${deliveryMethod}`)
                            : ''
                    }`}</Box>
                )}
                <Box pt="32px">{t('checkInLittleWhile')}</Box>
            </Typography>
            <Box className={classes.btnGroupContainer}>
                <Box width="100%" className={classes.buttonContainer}>
                    <CustomButtonContain
                        disabled={!isApiSuccess}
                        variant="contained"
                        width="100%"
                        onClick={handleSubmit}
                    >
                        {isUpdateRequest
                            ? t('updateDeliverySchedule')
                            : t('requestReDelivery')}
                    </CustomButtonContain>
                </Box>
                <Box width="100%" className={classes.buttonContainer}>
                    <CustomButtonOutline
                        variant="outlined"
                        width="100%"
                        onClick={() =>
                            history.push({
                                pathname: isUpdateRequest
                                    ? RoutesEnum.UpdateDelivery
                                    : RoutesEnum.ReDelivery,
                                state: {
                                    slot: {
                                        start: moment(deliveryDateCd)
                                            .add(
                                                deliveryTimeCdDetails?.start,
                                                'hour',
                                            )
                                            .format(),
                                        end: moment(deliveryDateCd)
                                            .add(
                                                deliveryTimeCdDetails?.end,
                                                'hour',
                                            )
                                            .format(),
                                    },
                                    deliveryMethod: deliveryMethod,
                                    contactNumber: contactNumber,
                                    brand: brand,
                                },
                                search: queryString.stringify({
                                    ...queryString.parse(
                                        history.location.search,
                                    ),
                                    trackingId: trackingId,
                                }),
                            })
                        }
                    >
                        {isUpdateRequest
                            ? t('returnToScheduledRequest')
                            : t('returnToReDeliveryRequest')}
                    </CustomButtonOutline>
                </Box>
            </Box>
            <Inquiries />
        </Box>
    );
};

export default ReDeliveryConfirm;
