import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { AppContext } from '../../../index';

const useStyles = () => {
    const { showBanner } = React.useContext(AppContext);
    const style = makeStyles((theme) => {
        return {
            mainContainer: {
                overflow: 'auto',
            },
            container: {
                position: 'relative',
            },
            [theme.breakpoints.down('md')]: {
                mainContainer: {
                    height: `calc(100vh - 65px - ${
                        showBanner ? '36px' : '0px'
                    })`,
                },
                container: {
                    padding: '0 20px',
                    minHeight: `calc(100vh - 165px - 65px - ${
                        showBanner ? '36px' : '0px'
                    })`,
                },
            },
            [theme.breakpoints.up('md')]: {
                mainContainer: {
                    height: `calc(100vh - 73px - ${
                        showBanner ? '36px' : '0px'
                    })`,
                },
                container: {
                    padding: '0 24px',
                    minHeight: `calc(100vh - 165px - 73px - ${
                        showBanner ? '36px' : '0px'
                    })`,
                },
            },
            [theme.breakpoints.up('lg')]: {
                mainContainer: {
                    height: `calc(100vh - 73px - ${
                        showBanner ? '36px' : '0px'
                    })`,
                },
                container: {
                    padding: '0 62px',
                    minHeight: `calc(100vh - 149px - 73px - ${
                        showBanner ? '36px' : '0px'
                    })`,
                },
            },
        };
    });
    return style();
};

export default useStyles;
