//@flow

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    CustomButtonContain,
    CustomLabel,
    CustomTextInput,
    Divider,
    Required,
    TitleContainer,
    Inquiries,
} from '../utils';
import { history } from '../../../../index';
import RoutesEnum from '../../../constants/RoutesEnum';
import queryString from 'query-string';
import AppBreadcrumbs from '../../Common/AppBreadcrumbs';
import { useStyles } from './LandingPageStyles';

const LandingPage = () => {
    const { trackingId } = queryString.parse(location.search);
    const [searchIds, setSearchIds] = React.useState(['', '', '', '', '']);
    const [haveAccordion, setHaveAccordion] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const { t } = useTranslation();
    const classes = useStyles(haveAccordion);

    React.useEffect(() => {
        const splitOrder = trackingId
            ? trackingId
                  .split(',')
                  .map((splitOrder) =>
                      splitOrder.length === 0 ? '' : splitOrder.trim(),
                  )
                  .filter(
                      (splitOrder, index, ref) =>
                          ref.indexOf(splitOrder) === index,
                  )
            : ['', '', '', '', ''];

        if (splitOrder.filter((item) => item.length > 0).length > 1)
            setHaveAccordion(true);

        for (let index = 0; index < 5; index++)
            splitOrder[index] = splitOrder[index] || '';

        setSearchIds(splitOrder);
    }, []);

    React.useEffect(() => {
        setTrackIdInUrl();
    }, [searchIds]);

    const setTrackIdInUrl = () => {
        history.push({
            pathname: RoutesEnum.LandingPage,
            search: queryString.stringify({
                ...queryString.parse(history.location.search),
                trackingId: searchIds.filter((id) => id.length !== 0).join(','),
            }),
        });
    };

    const setAccordion = () => {
        haveAccordion &&
            searchIds.length > 1 &&
            setSearchIds([searchIds[0], '', '', '', '']);
        setHaveAccordion(!haveAccordion);
    };

    const handleChangeInput = (index, value) => {
        const data = [...searchIds];
        data.splice(index, 1, value);
        setSearchIds(data);
    };

    const inCorrectIds = React.useMemo(
        () =>
            searchIds.filter(
                (item) =>
                    item.length !== 0 &&
                    (!/^\d+$/.test(item) || item.length !== 12),
            ),
        [searchIds],
    );

    const handleTrackShipment = () => {
        const isValidInput =
            inCorrectIds.length === 0 &&
            searchIds.filter((id) => id.length !== 0).length !== 0;

        if (isValidInput) {
            history.push({
                pathname: RoutesEnum.Summary,
                search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    trackingId: searchIds
                        .filter((id) => id.length !== 0)
                        .join(','),
                }),
            });
        } else {
            setShowErrorMessage(true);
            for (const [index, id] of searchIds.entries()) {
                if (inCorrectIds.includes(id)) {
                    return document
                        .getElementById(`slipNoInput${index}`)
                        ?.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    return (
        <Box>
            <AppBreadcrumbs />
            <TitleContainer
                title={t('trackYourShipment')}
                dividerProps={{ mt: '24px' }}
            >
                <Box pt="16px">{t('enterSlipNoToTrack')}</Box>
                <Box pt="8px">{t('youCanRequestReDelivery')}</Box>
            </TitleContainer>
            <Required />
            <Box pt="12px" id="slipNoInput0">
                <CustomLabel
                    labeltext={t('deliverySlipNo')}
                    isRequiredField={true}
                />
            </Box>
            <Box pt="8px">
                <CustomTextInput
                    variant="outlined"
                    value={searchIds[0]}
                    onChange={(e) => handleChangeInput(0, e.target.value)}
                />
            </Box>
            {showErrorMessage &&
                (searchIds[0].length <= 0 ||
                    inCorrectIds.includes(searchIds[0])) && (
                    <Box className={classes.errorMessage}>
                        {searchIds[0].length <= 0
                            ? t('pleaseEnterDeliverySlipNo')
                            : t('pleaseCheckDeliverySlipNo')}
                    </Box>
                )}
            <Typography variant="body">
                <Box pt="32px">{t('trackSlipNoUpto5')}</Box>
            </Typography>
            <Divider mt="16px" dividerWidth="752px" />
            <Box
                pt="8px"
                height="52px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                maxWidth="752px"
                style={{
                    cursor: 'pointer',
                }}
                onClick={setAccordion}
            >
                <Box>
                    <Typography variant="body" className={classes.headerTitle}>
                        {t('searchForOtherSlipNo')}
                    </Typography>
                </Box>
                <Box pl="8px">
                    <img
                        src={`/static/${haveAccordion ? 'minus' : 'plus'}.svg`}
                        style={{ padding: '10px' }}
                    ></img>
                </Box>
            </Box>
            {haveAccordion && (
                <Box>
                    <Box pt="16px" id="slipNoInput1">
                        <CustomLabel labeltext={t('deliverySlipNo')} />
                    </Box>
                    <Box pt="8px">
                        <CustomTextInput
                            variant="outlined"
                            value={searchIds[1]}
                            onChange={(e) =>
                                handleChangeInput(1, e.target.value)
                            }
                        />
                    </Box>
                    {showErrorMessage &&
                        inCorrectIds.includes(searchIds[1]) && (
                            <Box className={classes.errorMessage}>
                                {t('pleaseCheckDeliverySlipNo')}
                            </Box>
                        )}
                    <Box pt="32px" id="slipNoInput2">
                        <CustomLabel labeltext={t('deliverySlipNo')} />
                    </Box>
                    <Box pt="8px">
                        <CustomTextInput
                            variant="outlined"
                            value={searchIds[2]}
                            onChange={(e) =>
                                handleChangeInput(2, e.target.value)
                            }
                        />
                    </Box>
                    {showErrorMessage &&
                        inCorrectIds.includes(searchIds[2]) && (
                            <Box className={classes.errorMessage}>
                                {t('pleaseCheckDeliverySlipNo')}
                            </Box>
                        )}
                    <Box pt="32px" id="slipNoInput3">
                        <CustomLabel labeltext={t('deliverySlipNo')} />
                    </Box>
                    <Box pt="8px">
                        <CustomTextInput
                            variant="outlined"
                            value={searchIds[3]}
                            onChange={(e) =>
                                handleChangeInput(3, e.target.value)
                            }
                        />
                    </Box>
                    {showErrorMessage &&
                        inCorrectIds.includes(searchIds[3]) && (
                            <Box className={classes.errorMessage}>
                                {t('pleaseCheckDeliverySlipNo')}
                            </Box>
                        )}
                    <Box pt="32px" id="slipNoInput4">
                        <CustomLabel labeltext={t('deliverySlipNo')} />
                    </Box>
                    <Box pt="8px">
                        <CustomTextInput
                            variant="outlined"
                            value={searchIds[4]}
                            onChange={(e) =>
                                handleChangeInput(4, e.target.value)
                            }
                        />
                    </Box>
                    {showErrorMessage &&
                        inCorrectIds.includes(searchIds[4]) && (
                            <Box className={classes.errorMessage}>
                                {t('pleaseCheckDeliverySlipNo')}
                            </Box>
                        )}
                </Box>
            )}
            <Divider
                mt={`${haveAccordion ? '24px' : '8px'}`}
                dividerWidth="752px"
            />
            <Box pt="32px" width="100%" className={classes.buttonContainer}>
                <Box className={classes.customButton} width="100%">
                    <CustomButtonContain
                        variant="contained"
                        width="100%"
                        onClick={handleTrackShipment}
                    >
                        {t('trackShipment')}
                    </CustomButtonContain>
                </Box>
            </Box>
            <Inquiries />
        </Box>
    );
};

export default LandingPage;
