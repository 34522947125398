//@flow

import * as React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClientContext from '../ClientContext';
import { AppContext } from '../../../index';

const useStyles = (clientTheme) => {
    const { showBanner } = React.useContext(AppContext);

    const styles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loader: {
            borderRadius: '4px',
            backgroundColor: '#EAEAEA !important',
        },
        barColorPrimary: {
            backgroundColor: `${clientTheme.primaryColor} !important`,
        },
        [theme.breakpoints.down('md')]: {
            container: {
                height: `calc(100vh - 165px - 65px - ${
                    showBanner ? '36px' : '0px'
                })`,
            },
            logo: {
                width: '180px',
                height: '64px',
            },
            logo1: {
                height: '64px',
            },
            logox: {
                height: '64px',
                marginLeft: '16px',
            },
            loader: {
                width: '200px',
            },
        },
        [theme.breakpoints.up('md')]: {
            container: {
                height: `calc(100vh - 165px - 73px - ${
                    showBanner ? '36px' : '0px'
                })`,
            },
            logo: {
                width: '180px',
                height: '80px',
            },
            logo1: {
                height: '80px',
            },
            logox: {
                height: '80px',
                marginLeft: '20px',
            },
            loader: {
                width: '280px',
            },
        },
        [theme.breakpoints.up('lg')]: {
            container: {
                height: `calc(100vh - 149px - 73px - ${
                    showBanner ? '36px' : '0px'
                })`,
            },
        },
    }));

    return styles();
};

const Loader = () => {
    const { clientTheme } = React.useContext(ClientContext);
    const classes = useStyles(clientTheme);

    const getLogoComponent = () => {
        if (Array.isArray(clientTheme.brand.list)) {
            return (
                <>
                    {clientTheme.brand.list.map((name, index) => (
                        <img
                            key={index}
                            src={clientTheme.brand[`${name}logoUrl`]}
                            className={classes[`logo${index > 0 ? 'x' : '1'}`]}
                            alt={clientTheme.brand[`${name}logoImgAlt`]}
                        />
                    ))}
                </>
            );
        } else {
            return (
                <img
                    className={classes.logo}
                    src={clientTheme.brand.logoUrl}
                    alt={clientTheme.brand.logoImgAlt}
                />
            );
        }
    };

    return (
        <Box className={classes.container}>
            <Box>
                <Box display="flex" mb="15px" justifyContent="center">
                    {getLogoComponent()}
                </Box>
                <Box>
                    <LinearProgress
                        classes={{
                            root: classes.loader,
                            barColorPrimary: classes.barColorPrimary,
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Loader;
