//@flow

import * as React from 'react';
import {
    Box,
    MenuItem,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { history } from '../../../../index';
import {
    CustomButtonContain,
    CustomButtonOutline,
    CustomSelect,
    CustomTextInput,
    Divider,
    Inquiries,
    LinkText,
    Required,
    TitleContainer,
} from '../utils';
import AppBreadcrumbs from '../../Common/AppBreadcrumbs';
import RoutesEnum from '../../../constants/RoutesEnum';
import { ServiceTemporaryUnavailable, ExcuseUs } from '../MessagePage';
import ClientContext from '../../ClientContext';
import { useGetDeliverySlot } from '../../../../jsx/hooks/useOrderTrack';
import deliveryLocation from '../../../../locales/ja.json';
import Loader from '../../Common/Loader';
import { useStyles } from './ReDeliveryStyles';

const DELIVERY_LOCATION: any = deliveryLocation.deliveryMethod;
const deliveryLocationList: any = Object.keys(DELIVERY_LOCATION);

const ReDelivery = (props) => {
    const {
        isUpdateRequest,
        location: { state },
    } = props;

    const { t, i18n } = useTranslation();
    const location = useLocation();
    const classes = useStyles();

    const { clientId, clientTheme } = React.useContext(ClientContext);
    const [selectedDate, setSelectedDate] = React.useState();
    const [selectedTime, setSelectedTime] = React.useState();
    const [deliveryLoc, setDeliveryLocation] = React.useState(null);
    const [contactNumber, setContactNumber] = React.useState();
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);

    const { brand } = state || {};

    const { trackingId } = queryString.parse(location.search);
    const ordersIds = trackingId ? trackingId.split(',') : [];
    const orderId = ordersIds.length > 0 ? ordersIds[0] : '';

    let trackOrderQuery = useGetDeliverySlot({
        clientId,
        orderId,
    });

    const resData =
        trackOrderQuery?.data?.result?.resultBody?.deliveryDateTimeList;

    const getTimeBydate = () => {
        let timeByDate = {};
        resData?.forEach((item) => {
            timeByDate[item.deliveryDateCd] = item.timeList;
        });

        return timeByDate;
    };

    const timeByDate = React.useMemo(getTimeBydate, [resData]);

    React.useEffect(() => {
        if (!state?.slot) return;
        const dateId = moment(state?.slot?.start).format('YYYYMMDD');
        const timeId = `${moment(state?.slot?.start).format('HH')}${moment(
            state?.slot?.end,
        ).format('HH')}`;

        if (!timeByDate[dateId]) {
            selectedDate && setSelectedDate();
            selectedTime && setSelectedTime();
            return;
        } else if (
            !timeByDate[dateId].some(
                (item) => `${item.start}${item.end}` === timeId,
            )
        ) {
            setSelectedDate(dateId);
            selectedTime && setSelectedTime();
            return;
        }

        setSelectedDate(dateId);
        setSelectedTime(timeId);
    }, [timeByDate]);

    React.useEffect(() => {
        if (!state) {
            history.push({
                pathname: RoutesEnum.Details,
                search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    trackingId: trackingId,
                }),
            });
        }
        state?.deliveryMethod && setDeliveryLocation(state.deliveryMethod);
        state?.contactNumber && setContactNumber(state.contactNumber);
    }, []);

    const checkIsPhoneNumberValid = () => {
        if (!contactNumber) return false;
        if (!/^\d+$/.test(contactNumber)) return false;
        if (contactNumber.length !== 10 && contactNumber.length !== 11)
            return false;
        if (!contactNumber.startsWith(0)) return false;
        return true;
    };

    const isPhoneNumberValid = React.useMemo(checkIsPhoneNumberValid, [
        contactNumber,
    ]);

    const isValidInput = React.useMemo(
        () => selectedDate && selectedTime && isPhoneNumberValid,
        [selectedDate, selectedTime, isPhoneNumberValid],
    );

    if (trackOrderQuery?.isFetching) return <Loader />;

    if (trackOrderQuery?.isError) {
        if (trackOrderQuery?.error?.message === 'Network Error')
            return <ExcuseUs />;
        else return <ServiceTemporaryUnavailable />;
    }

    const handleSubmit = () => {
        if (isValidInput) {
            const selectedDeliveryTimeCdDetails = timeByDate[
                selectedDate
            ]?.find((item) => `${item.start}${item.end}` === selectedTime);

            history.push({
                pathname: isUpdateRequest
                    ? RoutesEnum.ConfirmUpdateDelivery
                    : RoutesEnum.ConfirmReDelivery,
                search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    trackingId: trackingId,
                }),
                state: {
                    deliveryDateCd: selectedDate,
                    deliveryTimeCdDetails: selectedDeliveryTimeCdDetails,
                    deliveryMethod: deliveryLoc,
                    contactNumber: contactNumber,
                    brand: brand,
                },
            });
        } else {
            setShowErrorMessage(true);
            if (!selectedDate)
                return document
                    .getElementById('dateInput')
                    ?.scrollIntoView({ behavior: 'smooth' });
            if (!selectedTime)
                return document
                    .getElementById('timeInput')
                    ?.scrollIntoView({ behavior: 'smooth' });
            if (!isPhoneNumberValid)
                return document
                    .getElementById('phoneNumberInput')
                    ?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box>
            <AppBreadcrumbs />
            <TitleContainer
                title={`${
                    isUpdateRequest
                        ? t('updateReschedule')
                        : t('reDeliveryRequest')
                }`}
                dividerProps={{ mt: '16px' }}
            />
            <Required />
            <Box>
                <Box pt="12px">
                    <Typography variant="h2">
                        {t('estimatedeliveryDateC')}
                    </Typography>
                </Box>
                <Box pt="24px">
                    <Typography variant="body">
                        {t('selectDateAndTime')}
                    </Typography>
                </Box>
                <Box
                    pt="32px"
                    display="flex"
                    alignItems="center"
                    id="dateInput"
                >
                    <Box className={classes.label}>{t('deliveryDateC')}</Box>
                    <Box className={classes.required}>
                        <img src="/static/required.svg" alt="*"></img>
                    </Box>
                </Box>
                <Box pt="8px">
                    <CustomSelect
                        value={selectedDate}
                        variant="outlined"
                        defaultValue={'default'}
                        onChange={(e) => {
                            setSelectedDate(e.target.value);
                            const defaultTime = timeByDate[e.target.value].find(
                                (item) => item.id === '0000',
                            );
                            setSelectedTime(
                                `${defaultTime.start}${defaultTime.end}`,
                            );
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="default" disabled>
                            {t('selectDate')}
                        </MenuItem>
                        {Object.keys(timeByDate).map((dateKey) => {
                            return (
                                <MenuItem key={dateKey} value={dateKey}>
                                    {moment(dateKey, 'YYYYMMDD').format(
                                        'YYYY/MM/DD',
                                    )}
                                </MenuItem>
                            );
                        })}
                    </CustomSelect>
                    {showErrorMessage && !selectedDate && (
                        <Box className={classes.inputErrorText} pt="8px">
                            {t('selectDate')}
                        </Box>
                    )}
                </Box>
                <Box
                    pt="32px"
                    display="flex"
                    alignItems="center"
                    id="timeInput"
                >
                    <Box className={classes.label}>{t('deliveryTimeC')}</Box>
                    <Box className={classes.required}>
                        <img src="/static/required.svg" alt="*"></img>
                    </Box>
                </Box>
                <Box pt="8px">
                    <CustomSelect
                        value={selectedTime}
                        defaultValue={
                            timeByDate[selectedDate] ? 'default' : '0000'
                        }
                        variant="outlined"
                        onChange={(e) => setSelectedTime(e.target.value)}
                        disabled={selectedDate ? false : true}
                    >
                        <MenuItem value="default" disabled>
                            {t('selectTime')}
                        </MenuItem>
                        {timeByDate[selectedDate] ? (
                            timeByDate[selectedDate].map((time) => (
                                <MenuItem
                                    key={time.id}
                                    value={`${time.start}${time.end}`}
                                >
                                    {time.id !== `${time.start}${time.end}`
                                        ? t('anyTime')
                                        : `${time.start}:00 ~ ${time.end}:00`}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="0000" disabled>
                                {t('anyTime')}
                            </MenuItem>
                        )}
                    </CustomSelect>
                    {showErrorMessage && selectedDate && !selectedTime && (
                        <Box className={classes.inputErrorText} pt="8px">
                            {t('selectTime')}
                        </Box>
                    )}
                </Box>
                <Divider mt="32px" dividerWidth="752px" />
                <Box pt="32px">
                    <Typography variant="h2">
                        {t('deliveryLocationC')}
                    </Typography>
                </Box>
                <Box pt="24px">
                    <Typography variant="body">
                        {t('unattendedDeliveryMessage')}
                    </Typography>
                </Box>
                <Box pt="8px">
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="delivery-method"
                            value={deliveryLoc}
                            onChange={(e) =>
                                setDeliveryLocation(e.target.value)
                            }
                        >
                            {deliveryLocationList.map((locId) => (
                                <Box
                                    key={locId}
                                    mt="16px"
                                    height="20px !important"
                                >
                                    <FormControlLabel
                                        value={locId}
                                        className={classes.radioLabel}
                                        control={
                                            <Radio
                                                sx={{
                                                    color: '#ABABAB',
                                                    '&.Mui-checked': {
                                                        color: '#000000',
                                                    },
                                                }}
                                            />
                                        }
                                        label={t(`deliveryMethodC.${locId}`)}
                                        sx={{
                                            color: '#000000',
                                            height: 'inherit',
                                            '&:hover': { color: '#2A2A2A' },
                                            '&:focus': { color: '#000000' },
                                        }}
                                    />
                                </Box>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box mt="24px" display="flex">
                    <Typography variant="caption" pr="6px">
                        <Box display="flex">
                            <Box>{`${t('unattendedDeliveryAgree1')}`}</Box>
                            {i18n.language === 'ja' ? (
                                <>
                                    <Box>
                                        <LinkText
                                            hRefLink={
                                                clientTheme.brand[
                                                    `fr${state?.brand}TermsAndConditionUrl`
                                                ]
                                            }
                                        >
                                            {t('termAndCondition')}
                                        </LinkText>
                                    </Box>
                                    <Box>{`${t(
                                        'unattendedDeliveryAgree2',
                                    )}`}</Box>
                                </>
                            ) : (
                                <>
                                    <Box mx="5px">
                                        {`${t('unattendedDeliveryAgree2')}`}
                                    </Box>
                                    <Box>
                                        <LinkText
                                            hRefLink={
                                                clientTheme.brand[
                                                    `fr${state?.brand}TermsAndConditionUrl`
                                                ]
                                            }
                                        >
                                            {t('termAndCondition')}
                                        </LinkText>
                                        .
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Typography>
                </Box>
                <Divider dividerWidth="752px" mt="32px" />
                <Box pt="32px">
                    <Typography variant="h2">{t('contactPhoneNo')}</Typography>
                </Box>
                <Box pt="24px">
                    <Typography variant="body">
                        {t('enterPhoneNoToContact')}
                    </Typography>
                </Box>
                <Box
                    mt="32px"
                    display="flex"
                    alignItems="center"
                    id="phoneNumberInput"
                >
                    <Box className={classes.label}>{t('phone')}</Box>
                    <Box className={classes.required}>
                        <img src="/static/required.svg" alt="*"></img>
                    </Box>
                </Box>
                <Box mt="8px">
                    <CustomTextInput
                        id="outlined-basic"
                        placeholder={t('enterPhoneNo')}
                        variant="outlined"
                        value={contactNumber}
                        onChange={(e) => {
                            e.preventDefault();
                            setContactNumber(e.target.value);
                        }}
                    />
                    {showErrorMessage && !isPhoneNumberValid && (
                        <Box className={classes.inputErrorText} pt="8px">
                            {contactNumber
                                ? t('enterValidPhoneNoErrMsg')
                                : t('enterPhoneNoErrMsg')}
                        </Box>
                    )}
                </Box>
                <Box className={classes.btnGroupContainer}>
                    <Box width="100%" className={classes.buttonContainer}>
                        <CustomButtonContain
                            variant="contained"
                            width="100%"
                            onClick={handleSubmit}
                        >
                            {t('preview')}
                        </CustomButtonContain>
                    </Box>
                    <Box width="100%" className={classes.buttonContainer}>
                        <CustomButtonOutline
                            variant="outlined"
                            width="100%"
                            onClick={() =>
                                history.push({
                                    pathname: RoutesEnum.Details,
                                    search: queryString.stringify({
                                        ...queryString.parse(
                                            history.location.search,
                                        ),
                                        trackingId: trackingId,
                                    }),
                                })
                            }
                        >
                            {t('cancel')}
                        </CustomButtonOutline>
                    </Box>
                </Box>
                <Inquiries />
            </Box>
        </Box>
    );
};

export default ReDelivery;
