//@flow

import * as React from 'react';
import { Box } from '@mui/material';
import ClientContext from '../ClientContext';
import useStyles from './AppBodyStyles';

import AppRoutes from '../AppBody/AppRoutes';
import AppFooter from './AppFooter';

const AppBody = (props): React.Node => {
    const { clientTheme } = React.useContext(ClientContext);
    const classes = useStyles(clientTheme);

    return (
        <Box className={classes.mainContainer}>
            <Box className={classes.container}>
                <AppRoutes {...props} />
            </Box>
            <AppFooter />
        </Box>
    );
};

export default AppBody;
