//@flow

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Divider, LinkText, TitleContainer } from '../utils';
import ClientContext from '../../ClientContext';

const ExcuseUs = () => {
    const { clientTheme } = React.useContext(ClientContext);
    const { t } = useTranslation();

    return (
        <Box pt="24px" pb="24px">
            <TitleContainer
                title={t('pleaseExcuseUs')}
                dividerProps={{ mt: '16px' }}
            />
            <Divider />
            <Box pt="24px" maxWidth="752px">
                <Typography variant="body">{t('excuseMessage')}</Typography>
            </Box>
            <Box pt="8px">
                <Typography variant="body" display="flex">
                    <Box pr="6px">
                        <div>
                            {`${t('assistanceContactAt')}`}
                            <span>
                                <LinkText>{t('weCareUniqlo')}</LinkText>
                            </span>
                            .
                        </div>
                    </Box>
                </Typography>
            </Box>
            <Box pt="24px">
                <Typography variant="title">{t('uniqlo')}</Typography>
            </Box>
            <Box pt="16px">
                <Typography variant="caption">
                    <LinkText hRefLink={clientTheme.brand['frUQFAQ']}>
                        {t('faq')}
                    </LinkText>
                </Typography>
            </Box>
            <Box pt="16px">
                <Typography variant="captionContact">
                    {clientTheme.brand['contactNo']}
                </Typography>
            </Box>
            <Box pt="24px">
                <Typography variant="title">{t('gu')}</Typography>
            </Box>
            <Box pt="16px">
                <Typography variant="caption">
                    <LinkText hRefLink={clientTheme.brand['frGUFAQ']}>
                        {t('faq')}
                    </LinkText>
                </Typography>
            </Box>
            <Box pt="16px">
                <Typography variant="captionContact">
                    {clientTheme.brand['contactNo']}
                </Typography>
            </Box>
        </Box>
    );
};

export default ExcuseUs;
