//@flow
import * as React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import ClientContext from '../../ClientContext';
import jaLanguage from '../../../../locales/ja.json';

const ORDER_STATUS = jaLanguage.orderStatus;

const useStyles = (clientTheme, isLatest) => {
    const style = makeStyles((theme) => {
        return {
            title: {
                fontWeight: 400,
                letterSpacing: '0px',
                textAlign: 'left',
            },
            subTitle: {
                fontWeight: 400,
                letterSpacing: '0px',
                textAlign: 'left',
            },
            checkIconContainer: {
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: isLatest ? '#000000' : '#DADADA',
            },
            checkIcon: {
                width: '10px',
                height: '8px',
                zIndex: 100,
            },
            [theme.breakpoints.down('md')]: {
                title: {
                    fontSize: '13px',
                    lineHeight: '20px',
                },
                subTitle: {
                    fontSize: '13px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.up('md')]: {
                title: {
                    fontSize: '16px',
                    lineHeight: '21px',
                },
                subTitle: {
                    fontSize: '13px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.up('lg')]: {
                title: {
                    fontSize: '17px',
                    lineHeight: '22px',
                },
                subTitle: {
                    fontSize: '14px',
                    lineHeight: '21px',
                },
            },
            description: {
                color: '#8992A9',
            },
            latestStatus: {
                backgroundColor: clientTheme.primaryColor,
            },
        };
    });
    return style();
};

const OrderDetailsStep = ({ isLatest, isLast, statusDetails }) => {
    const { clientTheme } = React.useContext(ClientContext);

    const { t } = useTranslation();
    const classes = useStyles(clientTheme, isLatest);

    const { trackingPageStatusCode, updatedDate } = statusDetails;
    const date = moment(updatedDate).format('YYYY/MM/DD HH:mm');

    return (
        <Box>
            <Box display="flex" mb={isLast ? 0 : '8px'}>
                <Box mr="12px">
                    <Box
                        display="flex"
                        alignItems="center"
                        height="100%"
                        flexDirection="column"
                    >
                        <Box
                            className={classes.checkIconContainer}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt="1px"
                        >
                            <img
                                src={'/static/check.svg'}
                                className={classes.checkIcon}
                            />
                        </Box>
                        {!isLast && (
                            <Box
                                display="flex"
                                flex={1}
                                width="0"
                                mt="8px"
                                mr="1px"
                            >
                                <Box borderLeft={`1px solid #DADADA`} />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <Box>
                            <Box className={classes.title}>
                                {ORDER_STATUS[trackingPageStatusCode]
                                    ? t(`orderStatus.${trackingPageStatusCode}`)
                                    : ''}
                            </Box>
                        </Box>
                        <Box>
                            <Box
                                mt="8px"
                                mb={`${isLast ? '0' : '16px'}`}
                                className={classes.subTitle}
                            >
                                {date}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderDetailsStep;
