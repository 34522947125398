import { makeStyles } from '@mui/styles';

export const useStyles = (detail) => {
    const style = makeStyles((theme) => {
        return {
            container: {
                maxWidth: '752px',
            },
            label1: {
                fontWeight: '400',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#000000',
            },
            label2: {
                fontWeight: '400',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#000000',
            },
            nextBtn: {
                width: '24px',
                height: '24px',
                padding: '5px 8.5px',
                color: '#ABABAB',
            },
            noDataText: {
                fontSize: '13px',
                fontWeight: '400',
                lineHeight: '20px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#EE0000',
            },
            errorText: {
                color: '#EE0000',
            },
            [theme.breakpoints.down('md')]: {
                label1: {
                    fontSize: '16px',
                    lineHeight: '24px',
                },
                label2: {
                    fontSize: '13px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.up('md')]: {
                label1: {
                    fontSize: '16px',
                    lineHeight: '24px',
                },
                label2: {
                    fontSize: '13px',
                    lineHeight: '20px',
                },
                buttonContainer: {
                    maxWidth: '348px',
                    display: 'flex',
                },
            },
            [theme.breakpoints.up('lg')]: {
                label1: {
                    fontSize: '17px',
                    lineHeight: '26px',
                },
                label2: {
                    fontSize: '14px',
                    lineHeight: '21px',
                },
                buttonContainer: {
                    maxWidth: '364px',
                    display: 'flex',
                },
            },
        };
    });
    return style();
};
