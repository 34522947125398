// @flow
import { createTheme } from '@mui/material/styles';

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 0,
            md: 600,
            lg: 960,
            xl: 960,
        },
    },
    typography: {
        fontFamily:
            '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Noto Sans CJK JP", Osaka, Meiryo, メイリオ, "MS PGothic", "ＭＳ Ｐゴシック", YuGothic, "Yu Gothic", "Hiragino Sans GB", Helvetica Neue, HelveticaNeue , Helvetica, Noto Sans, Roboto,Arial, "Arial Unicode MS", sans-serif;',
        h1: {
            fontWeight: 400,
            letterSpacing: '0px',
            textAlign: 'left',
        },
        h2: {
            fontWeight: 400,
            letterSpacing: '0px',
            textAlign: 'left',
        },
        h3: {
            fontWeight: 400,
            letterSpacing: '0px',
            textAlign: 'left',
        },
        body: {
            fontWeight: 400,
            letterSpacing: '0px',
            textAlign: 'left',
        },
        caption: {
            fontWeight: 400,
            letterSpacing: '0px',
            textAlign: 'left',
        },
        captionContact: {
            fontWeight: 600,
            letterSpacing: '0px',
            textAlign: 'left',
        },
        breadCrumb: {
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '0px',
            lineHeight: '14px',
            textAlign: 'left',
        },
        title: {
            fontWeight: '400',
            letterSpacing: '0px',
            textAlign: 'left',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: '3px',
                    height: '1px',
                    borderRadius: '2rem',
                },
                '*::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#111318',
            },
            colorDefault: {
                backgroundColor: '#ffffff',
            },
        },
    },
});

theme.typography = {
    ...theme.typography,
    h1: {
        ...theme.typography.h1,
        [theme.breakpoints.down('md')]: {
            fontSize: '22px',
            lineHeight: '31px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '28px',
            lineHeight: '39px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '32px',
            lineHeight: '45px',
        },
    },
    h2: {
        ...theme.typography.h2,
        [theme.breakpoints.down('md')]: {
            fontSize: '20px',
            lineHeight: '28px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '22px',
            lineHeight: '31px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            lineHeight: '34px',
        },
    },
    h3: {
        ...theme.typography.h3,
        [theme.breakpoints.down('md')]: {
            fontSize: '18px',
            lineHeight: '25px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '18px',
            lineHeight: '25px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px',
            lineHeight: '28px',
        },
    },
    body: {
        ...theme.typography.body1,
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '21px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
            lineHeight: '21px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
            lineHeight: '23px',
        },
    },
    caption: {
        ...theme.typography.caption,
        [theme.breakpoints.down('md')]: {
            fontSize: '13px',
            lineHeight: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '13px',
            lineHeight: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '13px',
            lineHeight: '20px',
        },
    },
    captionContact: {
        ...theme.typography.captionContact,
        [theme.breakpoints.down('md')]: {
            fontSize: '13px',
            lineHeight: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '13px',
            lineHeight: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '13px',
            lineHeight: '20px',
        },
    },
    title: {
        ...theme.typography.title,
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '21px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
            lineHeight: '21px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
            lineHeight: '23px',
        },
    },
};

export default theme;
