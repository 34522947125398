import { makeStyles } from '@mui/styles';

export const useStyles = () => {
    const style = makeStyles((theme) => {
        return {
            radioLabel: {
                '& .MuiFormControlLabel-label': {
                    fontSize: '11px !important',
                    fontWeight: '400 !important',
                    lineHeight: '13px !important',
                    letterSpacing: '0px !important',
                    textAlign: 'left !important',
                },
            },
            label: {
                fontWeight: 400,
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#000000',
            },
            required: {
                paddingLeft: '8px',
                height: '22px',
            },
            errorText: {
                color: '#EE0000',
            },
            inputErrorText: {
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '13px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#EE0000',
            },
            [theme.breakpoints.down('md')]: {
                btnGroupContainer: {
                    paddingTop: '8px',
                },
                buttonContainer: {
                    paddingTop: '24px',
                },
                label: {
                    fontSize: '13px',
                    lineHeight: '16px',
                },
                radioLabel: {
                    '& .MuiFormControlLabel-label': {
                        fontSize: '13px !important',
                        lineHeight: '16px !important',
                    },
                },
            },
            [theme.breakpoints.up('md')]: {
                btnGroupContainer: {
                    paddingTop: '32px',
                    display: 'flex',
                },
                buttonContainer: {
                    maxWidth: '348px',
                    marginRight: '24px',
                    display: 'flex',
                },
                label: {
                    fontSize: '13px',
                    lineHeight: '16px',
                },
                radioLabel: {
                    '& .MuiFormControlLabel-label': {
                        fontSize: '13px !important',
                        lineHeight: '16px !important',
                    },
                },
            },
            [theme.breakpoints.up('lg')]: {
                btnGroupContainer: {
                    paddingTop: '32px',
                    display: 'flex',
                },
                buttonContainer: {
                    maxWidth: '364px',
                    marginRight: '24px',
                    display: 'flex',
                },
                label: {
                    fontSize: '14px',
                    lineHeight: '17px',
                },
                radioLabel: {
                    '& .MuiFormControlLabel-label': {
                        fontSize: '14px !important',
                        lineHeight: '17px !important',
                    },
                },
            },
        };
    });
    return style();
};
