//@flow

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext, history } from '../../../index';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import RoutesEnum from '../../constants/RoutesEnum';

const AppBreadcrumbs = (): React.Node => {
    const location = useLocation();
    const { t } = useTranslation();
    const { showBanner } = React.useContext(AppContext);
    let { trackingId } = queryString.parse(location.search);
    const ordersIds = trackingId ? trackingId.split(',') : [];
    const orderId = ordersIds.length > 0 ? ordersIds[0] : '';

    const useStyles = makeStyles((theme) => {
        return {
            checkContainer: {
                width: '80px',
                height: '14px',
                right: 0,
                position: 'absolute',
                backgroundImage:
                    'linear-gradient( 270deg, #FFFFFF 21.11%, rgba(255, 255, 255, 0) 107.22%) !important',
            },
            mainContainer: {
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
            container: {
                width: 'max-content',
            },
            text: {
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '14px',
                letterSpacing: '0px',
                textAlign: 'left',
                textDecorationLine: 'underline',
                cursor: 'pointer',
            },
            disabledText: {
                textDecorationLine: 'None',
                cursor: 'default',
            },
            isSeperator: {
                padding: '0 4px',
            },
            [theme.breakpoints.down('md')]: {
                container: {
                    padding: `16px 0 24px`,
                },
            },
            [theme.breakpoints.up('md')]: {
                container: {
                    padding: `${showBanner ? '24px' : '16px'} 0 24px`,
                },
            },
            [theme.breakpoints.up('lg')]: {
                container: {
                    padding: `${showBanner ? '24px' : '16px'} 0 24px`,
                },
            },
        };
    });
    const classes = useStyles();
    let breadcrumbs = [];
    if (location.pathname === RoutesEnum.LandingPage) {
        breadcrumbs = [{ label: t('trackYourShipment'), disabled: true }];
    }
    if (location.pathname === RoutesEnum.Summary) {
        breadcrumbs = [
            {
                label: t('trackYourShipment'),
                onClick: () =>
                    history.push({
                        pathname: RoutesEnum.LandingPage,
                        search: queryString.stringify({
                            ...queryString.parse(history.location.search),
                            trackingId,
                        }),
                    }),
            },
            { label: `/`, disabled: true, isSeperator: true },
            { label: `${t('deliveryStatusList')}`, disabled: true },
        ];
    }
    if (
        location.pathname === RoutesEnum.Details ||
        [
            RoutesEnum.ReDelivery,
            RoutesEnum.UpdateDelivery,
            RoutesEnum.ConfirmReDelivery,
            RoutesEnum.ConfirmUpdateDelivery,
        ].indexOf(location.pathname) >= 0
    ) {
        breadcrumbs = [
            {
                label: t('trackYourShipment'),
                onClick: () =>
                    history.push({
                        pathname: RoutesEnum.LandingPage,
                        search: queryString.stringify({
                            ...queryString.parse(history.location.search),
                            trackingId,
                        }),
                    }),
            },
            { label: `/`, disabled: true, isSeperator: true },
            {
                label: `${t('deliveryStatusList')}`,
                onClick: () =>
                    history.push({
                        pathname: RoutesEnum.Summary,
                        search: queryString.stringify({
                            ...queryString.parse(history.location.search),
                            trackingId,
                        }),
                    }),
            },
            { label: `/`, disabled: true, isSeperator: true },
            {
                label: `${t('deliverySlipNo')}: ${orderId}`,
                disabled: true,
            },
        ];
    }

    return (
        <Box className={classes.mainContainer}>
            <Box className={classes.container}>
                <Box className={classes.checkContainer}></Box>
                <Box mr="35px" display="flex" alignItems="center">
                    {breadcrumbs.map((breadcrumb, index) => (
                        <Box
                            key={index}
                            className={`${classes.text} ${
                                breadcrumb.disabled && classes.disabledText
                            } ${breadcrumb.isSeperator && classes.isSeperator}`}
                            onClick={breadcrumb.onClick}
                        >
                            {breadcrumb.label}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default AppBreadcrumbs;
