const fastretailing = {
    brand: {
        logoUrl: '/static/fast-retailing-uniqlo-pre-logo.svg',
        logoImgAlt: 'ユニクロ｜UNIQLO',
        websiteUrl: 'https://www.uniqlo.com/jp/ja/',
        websiteLogo: '/static/uniqlo-favicon.ico',
        copyRights: 'Copyright © UNIQLO Co., Ltd. All rights reserved.',
    },
    primaryColor: '#EE1C25',
    backgroundColor: {
        100: '#F2F3F480',
        200: '#F4F4F4',
        300: '#F3F4F6',
        400: '#E7E9EE',
    },
    borderTheme: {
        100: '#dadada',
        200: '#767676',
        300: '#F3F4F6',
        focus: '#005DB5',
    },
    button: {
        color: '#1B1B1B',
        fontSize: '16px',
        fontWeight: 400,
        width: '364px',
        height: '52px',
    },
    bannerDateRange: {
        start: '2023-12-08 00:00:00',
        end: '2024-01-03 23:59:59',
    },
};

const fastretailingUniqloGu = {
    ...fastretailing,
    brand: {
        ...fastretailing.brand,
        list: ['frUniqlo', 'frGu'],
        frUniqlologoUrl: '/static/fast-retailing-uniqlo-logo.svg',
        frGulogoUrl: '/static/fast-retailing-gu-logo.svg',
        frUniqlologoImgAlt: 'ユニクロ｜UNIQLO',
        frGulogoImgAlt: 'GU',
        frUniqlowebsiteUrl: 'https://www.uniqlo.com',
        frGuwebsiteUrl: 'https://www.gu-global.com',
        frUQTermsAndConditionUrl:
            'https://faq.uniqlo.com/articles/FAQ/100006711',
        frGUTermsAndConditionUrl:
            'https://faq.gu-global.com/articles/FAQ/100006714',
        frUniqlocopyRights: 'Copyright © UNIQLO Co., Ltd. All rights reserved.',
        frGucopyRights: 'Copyright © GU Co., Ltd. All rights reserved.',
        frUQFAQ:
            'https://faq.uniqlo.com/pkb_Home?l=ja&c=category_uq%3AUQ_C1_4_1',
        frGUFAQ:
            'https://faq.gu-global.com/pkb_Home_GU?l=ja&c=category_gu%3AGU_C1_4',
        contactNo: '0120-201-620',
        helpLineTimings: (language) =>
            `(${new Date('1/1/2021 9:00').toLocaleString(language, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })} - ${new Date('1/1/2021 17:00').toLocaleString(language, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })})`,
    },
};

const defaultSetting = {
    brand: {
        logoUrl: '/static/locus-logo.svg',
        logoImgAlt: 'LOCUS',
        websiteUrl: 'https://locus.sh/',
        websiteLogo: '/static/locus-favicon.ico',
        copyRights: 'Locus © 2021 Mara Labs, Inc. All rights reserved',
    },
    backgroundColor: {
        100: '#F2F3F480',
        200: '#F4F4F4',
        300: '#F3F4F6',
        400: '#E7E9EE',
    },
    primaryColor: '#0072F5',
    borderTheme: {
        100: '#dadada',
        200: '#767676',
        300: '#F3F4F6',
        focus: '#005DB5',
    },
    button: {
        color: '#0072F5',
        fontSize: '12px',
        fontWeight: 700,
        width: '220px',
        height: '35px',
    },
};

export default {
    defaultSetting,
    fastretailing,
    fastretailingUniqloGu,
    'fr-e2e': fastretailingUniqloGu,
    'fr-tky': fastretailingUniqloGu,
    'fr-uat': fastretailingUniqloGu,
    'fr-devo': fastretailingUniqloGu,
};
