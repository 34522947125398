import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const supportedLngs = ['ja', 'en'];

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,
        react: {
            useSuspense: true,
        },
        backend: {
            loadPath: '/{{lng}}.json',
            allowMultiLoading: true,
        },
        supportedLngs,
    });

export default i18n;
export { i18n, supportedLngs };
