export const RESCHEDULE_BOTTOM_BUTTON_STATUS_CODE = [
    101,
    201,
    301,
    302,
    401,
    501,
    502,
    601,
    602,
    603,
    604,
    605,
    654,
    660,
    661,
    662,
    663,
    664,
    'default',
];

export const REDELIVERY_TOP_BUTTON_STATUS_CODE = [651, 652, 653, 701];

export const IGNORED_CURRENT_STATUS_CODE_BUTTON_RULE = [];

export const IGNORED_CURRENT_STATUS_CODE_BOTTOM_BUTTON_MESSAGE_UI = [
    101,
    201,
    301,
    401,
    501,
    502,
    601,
    602,
    603,
    604,
    605,
    664,
];

export const IGNORED_REPEATED_STATUS_CODE_LIST_DISPLAY = [101, 201, 302];

export const USE_UNATTENDED_DELIVERY_METHOD = [
    '玄関ドア前',
    '宅配ボックス',
    'メーターボックス',
    '物置',
    '車庫',
    '自転車カゴ',
    '建物内受付・管理人預け',
];

export const DO_NOT_USE_UNATTENDED_DELIVERY_METHOD = ['置き配を利用しない'];

export const ANY_TIME_SLOT_ID = '0821';
