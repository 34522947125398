// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ClientContext from '../components/ClientContext';
import { getItem, setItem, deleteItem } from '../constants/LocalStorage';
import i18n from '../../i18n';

import Routes from './Routes';

type Props = {
    history: $FixMe,
};

const Root = (props: Props): React.Node => {
    const { history } = props;
    const { t } = useTranslation();
    const { clientTheme } = React.useContext(ClientContext);

    React.useEffect(() => {
        const i18nConfig = getItem('i18nConfig');
        let language = 'ja';
        if (!i18nConfig) setItem('i18nConfig', { language });
        else language = i18nConfig.language;
        i18n.changeLanguage(language).then(
            () => (document.title = t('websiteTitle')),
        );

        if (document.getElementById('fav-icon'))
            document.getElementById('fav-icon').href =
                clientTheme.brand.websiteLogo;
    }, []);

    return <Routes history={history} />;
};

export default Root;
