//@flow

import * as React from 'react';
import { Box } from '@mui/material';
import queryString from 'query-string';
import AppHeader from './AppHeader';
import AppBody from './AppBody/AppBody';
import { AppContext } from '../../index';
import ClientContext from './ClientContext';
import moment from 'moment-timezone';

const AppContainer = (): React.Node => {
    const { showBanner } = queryString.parse(location.search);
    const { setShowBannerMsg } = React.useContext(AppContext);
    const { clientTheme } = React.useContext(ClientContext);

    React.useEffect(() => {
        if (clientTheme.bannerDateRange) {
            const startDate = moment(
                clientTheme.bannerDateRange.start,
                'YYYY-MM-DD HH:mm:ss',
            );
            const endDate = moment(
                clientTheme.bannerDateRange.end,
                'YYYY-MM-DD HH:mm:ss',
            );
            if (moment().isBetween(startDate, endDate)) {
                setShowBannerMsg(true);
            } else setShowBannerMsg(false);
        }

        if (showBanner) setShowBannerMsg(showBanner === 'true' ? true : false);
    }, []);
    return (
        <Box>
            <AppHeader />
            <AppBody />
        </Box>
    );
};

export default AppContainer;
