//@flow

import * as React from 'react';
import { Box, Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import i18n, { supportedLngs } from '../../../i18n';
import ClientContext from '../ClientContext';
import { setItem } from '../../constants/LocalStorage';

const useStyles = () => {
    const style = makeStyles((theme) => {
        return {
            footerContainer: {
                width: '100%',
                backgroundColor: '#F4F4F4',
            },
            languageText: {
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#000000',
                cursor: 'pointer',
            },
            selectedLngText: {
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '20px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#000000',
                cursor: 'pointer',
            },
            copyRights: {
                borderTop: '1px solid #dadada',
                backgroundColor: '#F4F4F4',
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#6A6A6A',
            },
            [theme.breakpoints.down('md')]: {
                footerContainer: {
                    height: '165px',
                },
                lngToggler: {
                    padding: '36px 20px',
                },
                lngSplitter: {
                    height: '16px',
                    width: '1px',
                    backgroundColor: '#6A6A6A',
                    margin: '0px 12px',
                },
                copyRights: {
                    padding: '16px 20px',
                },
            },
            [theme.breakpoints.up('md')]: {
                footerContainer: {
                    height: '165px',
                },
                lngToggler: {
                    padding: '36px 24px',
                },
                lngSplitter: {
                    height: '16px',
                    width: '1px',
                    backgroundColor: '#6A6A6A',
                    margin: '0px 16px',
                },
                copyRights: {
                    padding: '16px 24px',
                },
            },
            [theme.breakpoints.up('lg')]: {
                footerContainer: {
                    height: '149px',
                },
                lngToggler: {
                    padding: '28px 62px',
                },
                lngSplitter: {
                    height: '16px',
                    width: '1px',
                    backgroundColor: '#6A6A6A',
                    margin: '0px 16px',
                },
                copyRights: {
                    padding: '26px 62px',
                },
                copyRightsSplitter: {
                    height: '16px',
                    width: '1px',
                    backgroundColor: '#6A6A6A',
                    margin: '0 16px',
                },
            },
        };
    });
    return style();
};

const AppFooter = (): React.Node => {
    const { clientTheme } = React.useContext(ClientContext);
    const { t } = useTranslation();
    const classes = useStyles(clientTheme);

    const getLabel = (lng) => {
        return (
            <Box display="flex" alignItems="center">
                {t(`languages.${lng}`)}
            </Box>
        );
    };
    const languagesMapping = {
        ja: { label: getLabel('ja'), value: 'ja' },
        en: { label: getLabel('en'), value: 'en' },
    };
    const languageOptions = supportedLngs.map((lng) => languagesMapping[lng]);

    const handleLngChange = (value) => {
        setItem('i18nConfig', { language: value });
        i18n.changeLanguage(value).then(
            () => (document.title = t('websiteTitle')),
        );
    };

    const getLanguages = () => {
        return languageOptions.map((lng, index) => (
            <Box key={lng.value} display="flex">
                {index > 0 && <Box className={classes.lngSplitter}></Box>}
                <Box
                    value={lng.value}
                    className={
                        i18n.language === lng.value
                            ? classes.selectedLngText
                            : classes.languageText
                    }
                    onClick={() => handleLngChange(lng.value)}
                >
                    {lng.label}
                </Box>
            </Box>
        ));
    };

    const getCopyRights = () => {
        if (Array.isArray(clientTheme.brand.list)) {
            return (
                <Box>
                    <Hidden lgUp>
                        <Box>
                            {clientTheme.brand.list.map((name, index) => (
                                <Box
                                    key={index}
                                    className={
                                        classes[
                                            `copyRights${index > 0 ? 'x' : '1'}`
                                        ]
                                    }
                                >
                                    {clientTheme.brand[`${name}copyRights`]}
                                </Box>
                            ))}
                        </Box>
                    </Hidden>
                    <Hidden lgDown>
                        <Box display="flex">
                            {clientTheme.brand.list.map((name, index) => (
                                <Box key={index} display="flex">
                                    {index > 0 && (
                                        <Box
                                            className={
                                                classes.copyRightsSplitter
                                            }
                                        ></Box>
                                    )}
                                    <Box
                                        className={
                                            classes[
                                                `copyRights${
                                                    index > 0 ? 'x' : '1'
                                                }`
                                            ]
                                        }
                                    >
                                        {clientTheme.brand[`${name}copyRights`]}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Hidden>
                </Box>
            );
        } else return <Box> {clientTheme.brand.copyRights} </Box>;
    };

    return (
        <Box className={classes.footerContainer}>
            <Box className={classes.lngToggler} display="flex">
                {getLanguages()}
            </Box>
            <Box className={classes.copyRights}>{getCopyRights()}</Box>
        </Box>
    );
};

export default AppFooter;
