//@flow

import * as React from 'react';
import { makeStyles, withStyles, styled } from '@mui/styles';
import { Box, Typography, Button, TextField, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClientContext from '../ClientContext';
import i18n from '../../../i18n';

export const TitleContainer = (props) => {
    const { title, children, dividerProps } = props;

    return (
        <Box>
            <Typography variant="h1">{title}</Typography>
            <Typography variant="body">{children}</Typography>
            <Divider {...dividerProps} behaveAbsolute={true} />
        </Box>
    );
};

export const Divider = (props) => {
    const { dividerWidth, behaveAbsolute } = props;
    const useStyles = () => {
        const style = makeStyles((theme) => {
            return {
                spliter: {
                    width: '100%',
                    maxWidth: dividerWidth ? dividerWidth : '100%',
                    height: '1px',
                    backgroundColor: '#DADADA',
                },
                [theme.breakpoints.down('md')]: {
                    spliter: {
                        ...(behaveAbsolute && {
                            position: 'absolute',
                            left: 0,
                        }),
                    },
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return (
        <Box {...props}>
            <Box className={classes.spliter}></Box>
        </Box>
    );
};

export const Required = () => {
    const { t } = useTranslation();
    const useStyles = () => {
        const style = makeStyles(() => {
            return {
                label: {
                    fontSize: '13px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0px',
                    textAlign: 'left',
                    color: '#000000',
                },
                icon: {
                    width: '12px',
                    height: '12px',
                    color: '#005DB5',
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return (
        <Box pt="24px" display="flex" justifyContent="right" maxWidth="752px">
            <Box className={classes.label} pr="8px">
                {t('required')}
            </Box>
            <Box>
                <img
                    src="/static/required.svg"
                    alt="*"
                    className={classes.icon}
                />
            </Box>
        </Box>
    );
};

export const CustomButtonContain = (props) => {
    const StyledButton = withStyles({
        root: {
            color: '#FFFFFF !important',
            backgroundColor: `${
                props.disabled ? '#DADADA' : '#000000'
            } !important`,
            borderRadius: '0 !important',
            fontSize: '16px !important',
            fontWeight: '400 !important',
            height: '52px !important',
            lineHeight: '19px !important',
            letterSpacing: '0px !important',
            textAlign: 'center !important',
            padding: '16px 8px !important',
            width: '100% !important',
            '&:hover': {
                backgroundColor: '#000000 !important',
            },
            '&:focus': {
                backgroundColor: '#2A2A2A !important',
                border: '1px solid #005DB5 !important',
                paddingLeft: '3px !important',
                paddingRight: '3px !important',
            },
        },
    })((props) => <Button {...props} />);

    return <StyledButton {...props} />;
};

export const CustomButtonOutline = (props) => {
    const StyledButton = withStyles({
        root: {
            color: `${props.disabled ? '#FFFFFF' : '#000000'} !important`,
            backgroundColor: `${
                props.disabled ? '#DADADA' : '#FFFFFF'
            } !important`,
            border: `${
                props.disabled ? 'none' : '1px solid #000000'
            } !important`,
            borderRadius: '0 !important',
            fontSize: '16px !important',
            fontWeight: '400 !important',
            height: '52px !important',
            lineHeight: '19px !important',
            letterSpacing: '0px !important',
            textAlign: 'center !important',
            padding: '16px 8px !important',
            width: '100% !important',
            '&:hover': {
                backgroundColor: '#FFFFFF !important',
                border: '1px solid #2A2A2A !important',
                textColor: '#2A2A2A !important',
            },
            '&:focus': {
                backgroundColor: '#FFFFFF !important',
                border: '1px solid #005DB5 !important',
                paddingLeft: '3px !important',
                paddingRight: '3px !important',
            },
        },
    })((props) => <Button {...props} width="250px" />);

    return <StyledButton {...props} />;
};

export const CustomTextInput = (props) => {
    const useStyles = () => {
        const style = makeStyles((theme) => {
            return {
                textInput: {
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        border: '0px solid #767676',
                        maxWidth: '480px',
                        borderRadius: 0,
                        '&:hover fieldset': {
                            borderColor: '#5D5D5D',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '2px solid 005DB5',
                        },
                    },
                    '& .MuiOutlinedInput-input': {
                        padding: '0 8px',
                        height: '44px',
                        lineHeight: '19px',
                        color: '#000000',
                    },
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return <TextField {...props} className={classes.textInput} />;
};

export const CustomSelect = (props) => {
    const CssSelect = styled(Select)({
        '& .MuiOutlinedInput-input': {
            padding: '0 8px',
            lineHeight: '19px !important',
            color: '#000000 !important',
        },
        '& .MuiSvgIcon-root': {
            color: '#000000 !important',
        },
    });

    const StyledSelect = withStyles({
        root: {
            borderRadius: '0 !important',
            height: '44px !important',
            width: '100% !important',
            maxWidth: '480px !important',
        },
    })((props) => <CssSelect {...props}>{props.children}</CssSelect>);

    return <StyledSelect {...props} />;
};

export const CustomLabel = (props) => {
    const { labeltext, isRequiredField } = props;

    const useStyles = () => {
        const style = makeStyles((theme) => {
            return {
                label: {
                    fontWeight: 400,
                    letterSpacing: '0px',
                    textAlign: 'left',
                    color: '#000000',
                },
                required: {
                    paddingLeft: '8px',
                    height: '22px',
                },
                [theme.breakpoints.down('md')]: {
                    label: {
                        fontSize: '13px',
                        lineHeight: '16px',
                    },
                },
                [theme.breakpoints.up('md')]: {
                    label: {
                        fontSize: '13px',
                        lineHeight: '16px',
                    },
                },
                [theme.breakpoints.up('lg')]: {
                    label: {
                        fontSize: '14px',
                        lineHeight: '17px',
                    },
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box className={classes.label}>{labeltext}</Box>
            {isRequiredField && (
                <Box className={classes.required}>
                    <img src="/static/required.svg" alt="*"></img>
                </Box>
            )}
        </Box>
    );
};

export const LinkText = (props) => {
    const { children, hRefLink } = props;
    const useStyles = () => {
        const style = makeStyles((theme) => {
            return {
                label: {
                    cursor: 'pointer',
                    color: '#005DB5',
                    textDecorationLine: 'None',
                    '&:hover': {
                        color: '#006ED7',
                    },
                    '&:focus': {
                        border: '1px solid #005DB5',
                        color: '#005DB5',
                    },
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return (
        <a className={classes.label} href={hRefLink} target="_blank">
            {children}
        </a>
    );
};

export const Inquiries = () => {
    const { t } = useTranslation();
    const { clientTheme } = React.useContext(ClientContext);

    return clientTheme?.brand?.helpLineTimings ? (
        <>
            <Box pt="32px" pb="2px" display="flex">
                <Typography variant="caption" pr="6px">
                    {t('deliveryRequest', {})}
                </Typography>
            </Box>
            <Box pt="2px" pb="2px" display="flex">
                <Typography variant="caption" pr="6px">
                    {t('voiceService', {
                        phoneNumber: clientTheme.brand.contactNo,
                    })}
                </Typography>
            </Box>
            <Box pt="2px" pb="2px" display="flex">
                <Typography variant="caption" pr="6px">
                    {t('deliveryRequestTime', {})}
                </Typography>
            </Box>
            <Box pt="12px" pb="70px" display="flex">
                <Typography variant="caption" pr="6px">
                    {t('otherInquiry', {
                        timings: clientTheme.brand.helpLineTimings(
                            i18n.language,
                        ),
                        phoneNumber: clientTheme.brand.contactNo,
                    })}
                </Typography>
            </Box>
        </>
    ) : (
        <></>
    );
};
