import { instance } from '../middleware/axios';
import {
    SET_IS_FETCHING,
    GET_ORDER_DETAILS,
    CLEAR_ORDER_DETAILS,
} from '../constants/action-types';

export const orderDetailsIsFetching = (orderId) => ({
    type: SET_IS_FETCHING,
    orderId,
});

export const orderDetails = (orderId, data) => ({
    type: GET_ORDER_DETAILS,
    orderId,
    payload: data,
});

export const clearOrderDetails = () => ({ type: CLEAR_ORDER_DETAILS });

export const getOrderDetails = (clientId, orderId, cb) => (dispatch) => {
    dispatch(orderDetailsIsFetching(orderId));
    instance
        .get(`locus/clientId/${clientId}/order/slip-no/${orderId}`)
        .then((res) => {
            if (res?.data?.result?.resultBody)
                dispatch(orderDetails(orderId, res.data.result.resultBody));
            else {
                dispatch(orderDetails(orderId, null));
            }
        })
        .catch((ex) => {
            dispatch(orderDetails(orderId, null));
            if (cb) cb(false, ex.message);
        });
};

export const updateOrderDetails = (clientId, orderId, data, cb) => () => {
    instance
        .put(`locus/clientId/${clientId}/order/slip-no/${orderId}`, data)
        .then((res) => {
            if (cb) cb(true, res?.data?.result?.resultHeader);
        })
        .catch((ex) => {
            if (cb) cb(false, ex.message);
        });
};
