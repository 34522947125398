//@flow

import * as React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ClientContext from './ClientContext';
import { AppContext } from '../../index';

const useStyles = (clientTheme) => {
    const style = makeStyles((theme) => {
        return {
            headerContainer: {
                backgroundColor: '#FFFFFF',
                borderBottom: `1px solid ${clientTheme.borderTheme[100]}`,
            },
            ticker: {
                backgroundColor: '#EE0000',
                padding: '8px 16px',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                letterSpacing: '0px',
                textAlign: 'center',
                color: '#ffffff',
            },
            [theme.breakpoints.down('md')]: {
                headerContainer: {
                    height: '65px',
                    padding: '16px 20px',
                },
                logo: {
                    height: '33px',
                },
                logox: {
                    paddingLeft: '8px',
                },
            },
            [theme.breakpoints.up('md')]: {
                headerContainer: {
                    height: '73px',
                    padding: '16px 24px 17px 24px',
                },
                logo: {
                    height: '40px',
                },
                logox: {
                    paddingLeft: '10px',
                },
            },
            [theme.breakpoints.up('lg')]: {
                headerContainer: {
                    height: '73px',
                    padding: '16px 62px 17px 62px',
                },
                logo: {
                    height: '40px',
                },
                logox: {
                    paddingLeft: '10px',
                },
            },
        };
    });

    return style();
};

const AppHeader = () => {
    const { clientTheme } = React.useContext(ClientContext);
    const { showBanner } = React.useContext(AppContext);
    const { t } = useTranslation();
    const classes = useStyles(clientTheme);

    const getLogoComponent = () => {
        if (Array.isArray(clientTheme.brand.list)) {
            return (
                <>
                    {clientTheme.brand.list.map((name, index) => (
                        <a
                            key={index}
                            href={clientTheme.brand[`${name}websiteUrl`]}
                        >
                            <img
                                src={clientTheme.brand[`${name}logoUrl`]}
                                className={`${classes.logo} ${
                                    classes[`logo${index > 0 ? 'x' : '1'}`]
                                } `}
                                alt={clientTheme.brand[`${name}logoImgAlt`]}
                            />
                        </a>
                    ))}
                </>
            );
        } else {
            return (
                <a href={clientTheme.brand.websiteUrl}>
                    <img
                        src={clientTheme.brand.logoUrl}
                        className={classes.logo}
                        alt={clientTheme.brand.logoImgAlt}
                    />
                </a>
            );
        }
    };

    return (
        <Box>
            <Box className={classes.headerContainer}>{getLogoComponent()}</Box>
            {showBanner && (
                <Box className={classes.ticker}>
                    <Box>{t('bannerMsg1')}</Box>
                    <Box>{t('bannerMsg2')}</Box>
                </Box>
            )}
        </Box>
    );
};

export default AppHeader;
