//@flow

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../index';
import RoutesEnum from '../../../constants/RoutesEnum';
import { Divider, LinkText } from '../utils';
import {
    REDELIVERY_TOP_BUTTON_STATUS_CODE,
    ANY_TIME_SLOT_ID,
} from '../../../constants';
import {
    getButtonStatus,
    getCustomizedOrderDetails,
} from '../OrderDetails/OrderDetails';
import jaLanguage from '../../../../locales/ja.json';
import { useStyles } from './OrderSummaryStyles';

const ORDER_STATUS = jaLanguage.orderStatus;

const OrderSummaryItem = (props) => {
    const { orderId, detail } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles(detail);

    const {
        date,
        timeId,
        startTime,
        endTime,
        sortedList,
        filteredList,
    } = React.useMemo(() => getCustomizedOrderDetails(detail, t), [detail]);

    const { showBottomButton } = React.useMemo(
        () => getButtonStatus(sortedList),
        [sortedList],
    );

    const latestStatus = React.useMemo(
        () => (filteredList ? filteredList[0] : {}),
        [filteredList],
    );

    return (
        <Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.container}
                pt="24px"
            >
                <Box>
                    <Box className={classes.label1}>
                        {`${t('deliverySlipNo')}: ${orderId}`}
                    </Box>
                    {detail ? (
                        <Box>
                            <Box pt="8px" className={classes.label2}>
                                {`${
                                    showBottomButton
                                        ? t('estimatedeliveryDate')
                                        : t('deliveryDate')
                                }: ${date} ${
                                    timeId === ANY_TIME_SLOT_ID
                                        ? t('anyTime')
                                        : `${startTime} 〜 ${endTime}`
                                }`}
                            </Box>
                            <Box pt="4px" className={classes.label2}>
                                {`${t('deliveryStatus')}: ${
                                    ORDER_STATUS[
                                        latestStatus?.trackingPageStatusCode
                                    ]
                                        ? t(
                                              `orderStatus.${latestStatus?.trackingPageStatusCode}`,
                                          )
                                        : ''
                                }`}
                            </Box>
                            {detail?.brand && (
                                <Box pt="4px" className={classes.label2}>
                                    {`${t('brand')}: ${`${detail.brand} ${t(
                                        'onlineStore',
                                    )}`}`}
                                </Box>
                            )}
                            {REDELIVERY_TOP_BUTTON_STATUS_CODE.includes(
                                latestStatus?.trackingPageStatusCode,
                            ) && (
                                <Box pt="8px" className={classes.label2}>
                                    {t('needReRequestDelivery')}
                                </Box>
                            )}
                            <Box pt="8px">
                                <Typography
                                    variant="caption"
                                    onClick={() =>
                                        history.push({
                                            pathname: RoutesEnum.Details,
                                            search: queryString.stringify({
                                                ...queryString.parse(
                                                    history.location.search,
                                                ),
                                                trackingId: orderId,
                                            }),
                                        })
                                    }
                                >
                                    {i18n.language == 'en' && (
                                        <LinkText>{t('clickHere')}</LinkText>
                                    )}
                                    {t('viewDetails')}
                                    {i18n.language == 'ja' && (
                                        <LinkText>{t('clickHere')}</LinkText>
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box pt="8px" className={classes.noDataText}>
                            {t('noRecordForSlipNumber')}
                        </Box>
                    )}
                </Box>
            </Box>
            <Divider dividerWidth="752px" mt="16px" />
        </Box>
    );
};

export default OrderSummaryItem;
