import { makeStyles } from '@mui/styles';

export const useStyles = (haveAccordion) => {
    const style = makeStyles((theme) => {
        return {
            headerTitle: {
                fontWeight: `${haveAccordion ? 700 : 400} !important`,
            },
            errorMessage: {
                margin: '5px 0 0 0',
                fontWeight: 300,
                fontSize: '11px',
                color: '#EE0000',
            },
            [theme.breakpoints.down('md')]: {},
            [theme.breakpoints.up('md')]: {
                buttonContainer: {
                    maxWidth: '348px',
                    display: 'flex',
                },
            },
            [theme.breakpoints.up('lg')]: {
                buttonContainer: {
                    maxWidth: '364px',
                    display: 'flex',
                },
            },
        };
    });
    return style();
};
